
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { priceRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
const management = namespace("management");
const marketing = namespace("marketing");
const base = namespace("base");
@Component({
  components:{
  }
})
export default class ValueCardEdit extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action createCardTemplate;
  @marketing.Action queryCardTemplateDetail;
  @marketing.Action updateCardTemplate;
  @management.Action getAllStoreList;
  valueCardForm = {
    cardTemplateName: "",
    cardAmount: "",
    giftAmount: "",
    enableStatus: 1,
    checked: true,
    usefulLife: t("marketing.permanent"),
    validPeriod: -1,
    cardDesc: "",
    cardType: 2,
    cardTemplateCode: "",
    carryingAmount: "",
    usedStatus: undefined,
    applicableStores:[],

  };
  storeList: any=[]

  moneyRule = (rule, value, callback) => {
    if (priceRules(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-rational-amount")));
    }
  };
  valueCardRules = {
    cardTemplateName: [
      { required: true, message: t("marketing.please-input-card-name"), trigger: "blur" },
      { min: 1, max: 150, message: t("v210831.the-length-is-1-150"), trigger: "blur" }
    ],
    cardAmount: [
      { required: true, message: t("v210831.please-enter-the-recharge-amount"), trigger: "blur" },
      {
        validator: this.moneyRule
      }
    ],
    giftAmount: [
      { required: true, message: t("v210831.please-enter-the-give-amount"), trigger: "blur" },
      {
        validator: this.moneyRule
      }
    ]
  };
  //计算属性
  get computeCountMoney() {
    return Number(this.valueCardForm.cardAmount) + Number(this.valueCardForm.giftAmount);
  }
  get isAdd() {
    return this.$route.name === "ValueCardAdd";
  }
  get breadData() {
    return [
      { name: t("marketing.card-template"), path: "/marketing/card-template" },
      {
        name: this.isAdd ? t("v210831.new-stored-value-card-template") : t("v210831.edit-stored-value-card-template")
      }
    ];
  }
  created() {
    this.init();
  }
  mounted() {

  }

  init() {
    if (this.$route.name === "ValueCardEdit") {
      this.queryCardTemplateDetail({ cardTemplateCode: this.$route.params.id }).then(res => {
        this.valueCardForm.cardAmount = res.data.cardAmount;
        this.valueCardForm.cardDesc = res.data.cardDesc;
        this.valueCardForm.cardTemplateCode = res.data.cardTemplateCode;
        this.valueCardForm.cardTemplateName = res.data.cardTemplateName;
        this.valueCardForm.carryingAmount = res.data.carryingAmount;
        this.valueCardForm.enableStatus = res.data.enableStatus;
        this.valueCardForm.giftAmount = res.data.giftAmount;
        this.valueCardForm.usedStatus = res.data.usedStatus;

        this.storeList = res.data.storesList
          ? res.data.storesList.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
          : []

        this.valueCardForm.applicableStores = res.data.applicableStores
      });
    }
  }

  searchStore(query) {
    this.getAllStoreList({
      pageNum: 1,
      pageSize: 10,
      searchKey: query
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
          item.label = item.storeName;
          item.value = item.storeCode;
          return item;
        })
        : []
    })
  }
  submitForm() {
    let resForm = this.$refs.valueCardForm as any;
    resForm.validate(valid => {
      if (valid) {
        if (this.$route.name === "ValueCardEdit") {
          // alert("submit!");
          this.updateCardTemplate(this.valueCardForm).then(res => {
            console.log("[ res ]", res);
            this.$message.success(t("v210831.card-template-updated"));
            this.$router.push("/management/card-template");
          });
        } else {
          this.createCardTemplate(this.valueCardForm).then(res => {
            console.log("[ res ]", res);
            this.$message.success(t("v210831.card-template-saved"));
            this.$router.push("/management/card-template");
          });
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
